.altai-theme-admin .menus-form {
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  font-size: small;
}
.altai-theme-admin .menus-form .menu-col-1 {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  flex-basis: 100%;
  overflow: hidden;
  -moz-transition: flex-basis 0.1s ease;
  -o-transition: flex-basis 0.1s ease;
  -webkit-transition: flex-basis 0.1s ease;
  transition: flex-basis 0.1s ease;
  background-color: #F5F5F5;
}
.altai-theme-admin .menus-form .menu-col-1.show-menu-form {
  flex-basis: 50%;
}
.altai-theme-admin .menus-form .menu-col-1.empty-menu-list {
  flex-basis: 0;
}
.altai-theme-admin .menus-form .menu-col-2 {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  flex-basis: 0;
  overflow: hidden;
  -moz-transition: flex-basis 0.1s ease;
  -o-transition: flex-basis 0.1s ease;
  -webkit-transition: flex-basis 0.1s ease;
  transition: flex-basis 0.1s ease;
}
.altai-theme-admin .menus-form .menu-col-2.show-menu-form {
  flex-basis: 50%;
}
.altai-theme-admin .menus-form .menu-col-2.empty-menu-list {
  flex-basis: 100%;
}
.altai-theme-admin .menus-form .menu-item-form {
  height: 300px;
  padding: 5px;
}
.altai-theme-admin .menus-form .menu-item-form table {
  margin: 0;
}
.altai-theme-admin .menus-form .menu-item-form h3 {
  margin-bottom: 10px;
  margin-top: 0;
}
.altai-theme-admin .menus-update button {
  margin: 7px;
}
